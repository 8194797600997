<script>
export default {
  name: "MapboxLayerData",
  props: {
    name: {
      type: String,
      default: null,
    },
    refId: {
      type: String,
      default: null,
    },
    location: {
      type: Object,
      default: null,
    },
    offset: {
      type: Number,
      default: 0,
    },
    rotate: {
      type: Number,
      default: 0,
    },
    line: {
      type: Array,
      default: null,
    },
    targetType: {
      type: Object,
      default: null,
    },
    targetOwner: {
      type: Object,
      default: null,
    },
    pointType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
