<script>
export default {
  name: "MapboxCityPositionData",
  props: {
    name: {
      type: String,
      default: null,
    },
    cityTarget: {
      type: Object,
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
    iconName: {
      type: String,
      default: null,
    },
    colorName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
