<template>
  <div>
    <map-box
      style="position: absolute"
      :mapHeight="260"
      ref="mapBox"
      @ready-event="mapboxReadyEvent"
      @style-change-start-event="mapboxStyleChangeStartEvent"
    />
    <div
      v-if="isMapboxButtonShowPosition"
      style="margin-top: 25px; right: 25px; position: absolute"
    >
      <button
        class="map-select"
        :class="{ 'map-active': !isShowLine }"
        @click="onClickMapboxShowLine(false)"
      >
        현 위치
      </button>
      <button
        class="map-select"
        :class="{ 'map-active': isShowLine }"
        @click="onClickMapboxShowLine(true)"
      >
        이동경로
      </button>
    </div>
    <div
      class="map-btn"
      v-if="isMapboxButtonShowChange"
      style="right: 24px; position: absolute"
      :style="{ 'margin-top': mapboxButtonShowChangeHeight + 'px' }"
    >
      <button
        class="small"
        style="display: contents"
        @click="onMapboxChangeShow"
      >
        <img v-if="isMapTypeChange" src="@/assets/images/map/ic_map_type_selected.svg" class="-\33 612"/>
        <img v-if="!isMapTypeChange" src="@/assets/images/map/ic_map_type.svg" class="-\33 612"/>
      </button>
    </div>
    <div
        v-if="isMapboxButtonShowPoint"
        style="right: 24px; position: absolute"
        :style="{ 'margin-top': mapboxButtonShowPointHeight + 'px' }"
    >
      <button
          class="small"
          style="display: contents"
          @click="onMapboxPointCreateShow"
      >
        <img v-if="isPointCreate" src="@/assets/images/map/ic_map_point_create_selected.svg" class="-\33 612"/>
        <img v-if="!isPointCreate" src="@/assets/images/map/ic_map_point_create.svg" class="-\33 612"/>
      </button>
    </div>

    <div v-if="isMapTypeChange" class="map-type-change">
      <span> 지도유형 </span>
      <button @click="onMapboxChangeClose">
        <img src="@/assets/images/map/ic_map_close.svg" />
      </button>
      <img
          class="terrain"
          :class="{ 'map-type-imag-active': isTypeSelectName == 'terrain' }"
          @click="onMapboxChangeSelectImageName('terrain')"
      />
      <img
        class="satellite"
        :class="{ 'map-type-imag-active': isTypeSelectName == 'satellite' }"
        @click="onMapboxChangeSelectImageName('satellite')"
      />
      <img
          class="normal"
          :class="{ 'map-type-imag-active': isTypeSelectName == 'normal' }"
          @click="onMapboxChangeSelectImageName('normal')"
      />
    </div>
    <!--포인트 등록-->
    <div class="map-point-div" v-if="isPointCreate">
      <div class="select-image">
        <img class="create" src="@/assets/images/map/ic_map_point_create_position.svg"/>
      </div>

      <div class="map-point-create">
        <div class="map-point-title">
          <span>포인트 등록</span>
        </div>
        <div class="select-group flexB">
          <span>구분선택</span>
          <div class="select" :class="{active : pointSelectType == 'Home'}" @click="onClickPointCreate('Home')">
            <span> {{$t("point-type-name-home")}} </span>
          </div>
          <div class="select" :class="{active : pointSelectType == 'Caution'}" @click="onClickPointCreate('Caution')">
            <span> {{$t("point-type-name-caution")}} </span>
          </div>
          <div class="select" :class="{active : pointSelectType == 'Danger'}" @click="onClickPointCreate('Danger')">
            <span> {{$t("point-type-name-danger")}} </span>
          </div>
          <div class="select" :class="{active : pointSelectType == 'Search'}" @click="onClickPointCreate('Search')">
            <span> {{$t("point-type-name-search")}} </span>
          </div>
          <div class="select" :class="{active : pointSelectType == 'Target'}" @click="onClickPointCreate('Target')">
            <span> {{$t("point-type-name-target")}} </span>
          </div>
        </div>

        <div class="insert-data">
          <span>메모 입력</span>
          <textarea
              @keyup="lengthLimit"
              maxlength="51"
              v-model="pointMemo"
              :placeholder="[$t('point-register-textarea-placeholder-text')]"
          ></textarea>
          <div class="btn-wrap">
            <button class="medium point margin6" @click="onMapboxPointCreateCall">
              확인
            </button>
            <button class="medium" @click="onMapboxPointCreateShow">
              취소
            </button>
          </div>
        </div>

      </div>
    </div>


    <!--포인트 수정-->
    <div class="map-point-modify-div"  v-if="isPointModify">
      <div class="map-point-create">
        <div class="map-point-title">
          <span>포인트 수정</span>
        </div>
        <div class="map-point-data">
          <span>등록일시</span>
          <div class="map-point-content">
            <span> {{ pointData ? moment(pointData.registerDate).format("YYYY.MM.DD HH:mm") : "" }} / {{ pointData ? pointData.registerUserName : ""}} </span>
          </div>
        </div>
        <div class="map-point-data">
          <span>구분선택</span>
          <div class="map-point-content">
            <span> {{ pointData ? $t(getPointTypeName(pointData.type )) : "" }} </span>
          </div>
        </div>

        <div class="insert-data">
          <span>메모 입력</span>
          <textarea @keyup="lengthLimit"  maxlength="51" v-model="pointMemo"  :placeholder="[$t('point-register-textarea-placeholder-text')]"
          ></textarea>
          <div class="btn-wrap">
            <button class="medium point margin6" @click="onMapboxPointModifyCall">
              확인
            </button>
            <button class="medium" @click="onMapboxPointModifyShow(null)">
              취소
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
//vue 에서 videojs 사용 하기 위한 install

import MapBox from "./MapBox";
import MapboxCityPositionData from "./MapboxCityPositionData";
import MapboxPositionTimeData from "./MapboxPositionTimeData";
import MapboxLayerData from "./MapboxLayerData";
import MapboxCityPosition from "./MapboxCityPosition";
import MapDataType from "../enum/MapDataType";
import MapboxTargetOwner from "./MapboxTargetOwner";
import {getPointTypeName} from "@/api/point";
import moment from "moment";

import axios from "axios";

export default {
  name: "MapboxView",
  components: { MapBox },
  data() {
    return {
      moment: moment,
      //버튼 관련 변수
      pointMemo : null,
      pointSelectType:'Caution',
      isPointCreate : false,
      isPointModify : false,
      isMapTypeChange: false,
      isMapboxButtonShowPosition: false,
      isMapboxButtonShowChange: false,
      isMapboxButtonShowPoint:false,
      mapboxButtonShowChangeHeight: 87,
      mapboxButtonShowPointHeight: 40,
      isShowLine: false,
      isTypeSelectName: "normal",
      //맵박스 관련 변수
      isMapboxReady: false,
      mapboxTimer: null,
      movePoint: null,

      cityList: null,

      //contents play data
      playerData: null,

      userMyData: null,
      userOtherDataList: [],

      droneMyData: null,
      droneOtherDataList: [],

      controllerOtherDataList: [],

      pointDataList: [],

      liveMyData: null,
      liveOtherDataList: [],

      pointData : null,
    };
  },
  created() {
    this.mapboxReadyTimer();
  },
  watch: {
    isMapboxButtonShowPosition: function (change) {
      if (change) {
        this.mapboxButtonShowChangeHeight = 87;
      }
    },
    isMapboxButtonShowChange: function (change) {
      if (change) {
        if (this.isMapboxButtonShowPosition) {
          this.mapboxButtonShowChangeHeight = 87;
        } else {
          this.mapboxButtonShowChangeHeight = 25;
        }
      }
    },
    isMapboxButtonShowPoint: function (change){
      if (change) {
        if (this.isMapboxButtonShowPoint) {
          if (this.isMapboxButtonShowPosition) {
            this.mapboxButtonShowPointHeight = 157;
          } else {
            this.mapboxButtonShowPointHeight = 87;
          }
        } else {
          if (this.isMapboxButtonShowPosition) {
            this.mapboxButtonShowPointHeight = 87;
          } else {
            this.mapboxButtonShowPointHeight = 25;
          }
        }
      }
    },
  },
  methods: {
    getPointTypeName : function(type){
      return getPointTypeName(type);
    },
    onClickPointCreate: function(select) {
      this.pointSelectType = select;
    },
    //textarea 글자 수 제한
    lengthLimit() {
      if (this.pointMemo.trim().length >= 51) {
        this.pointMemo = this.pointMemo.slice(0, -1);
        alert(this.$t("alert-message-characters-over"));
      }
    },
    //기본 안보여짐
    //포이트 생성 변경 버튼(아이콘모양) 보이게 하기
    onMapboxButtonShowPoint: function () {
      this.isMapboxButtonShowPoint = true;
    },

    //기본 안보여짐
    //현위치, 이동경로 버튼 보이게 하게
    onMapboxButtonShowPosition: function () {
      this.isMapboxButtonShowPosition = true;
    },

    //기본 안보여짐
    //맵타입 변경 버튼(아이콘모양) 보이게 하기
    onMapboxButtonShowChange: function () {
      this.isMapboxButtonShowChange = true;
    },

    onMapboxPointCreateCall:function(){
      var position = this.$refs.mapBox.onGetMapboxCenterData();
      let sendData = {
        lng : position.lng,
        lat : position.lat,
        type : this.pointSelectType,
        memo : this.pointMemo
      }
      this.$emit("point-create", sendData);
      this.pointMemo = null;
    },

    onMapboxPointModifyCall:function(){
      if(this.pointData != null) {
        this.pointData.memo = this.pointMemo;
        this.$emit("point-modify", this.pointData);
        this.pointMemo = null;
      }
    },

    // 1. statistics/event/addr/list api 이용.
    // 오늘 날짜 from, to 넣어서 api 요청, previous list(오늘날짜의 결과 하나만 있음) 중 첫번쨰 객체 넣어 준다.
    // busan: 0
    // chungcheongbukDo: 0
    // chungcheongnamDo: 0
    // daegu: 0
    // daejeon: 0
    // date: "2021-10-23"
    // gangwonDo: 0
    // gwangju: 0
    // gyeonggiDo: 0
    // gyeongsangbukDo: 0
    // gyeongsangnamDo: 0
    // incheon: 0
    // jejuDo: 0
    // jeollabukDo: 0
    // jeollanamDo: 0
    // sejongSi: 0
    // seoul: 0
    // total: 1
    // ulsan: 0
    onUpdateCityValue: function (addrDataList) {
      const list = [];

      for (const [key, value] of Object.entries(addrDataList)) {
        if (key == "date" || key == "total") continue;
        const data = Object.assign({}, MapboxCityPositionData);

        switch (key) {
          case "busan":
            data.name = MapboxCityPosition.ID_BUSAN.name;
            data.cityTarget = MapboxCityPosition.ID_BUSAN.point;
            data.value = value;
            break;
          case "chungcheongbukDo":
            data.name = MapboxCityPosition.ID_CHUNGCHEONGBUKDO.name;
            data.cityTarget = MapboxCityPosition.ID_CHUNGCHEONGBUKDO.point;
            data.value = value;
            break;
          case "chungcheongnamDo":
            data.name = MapboxCityPosition.ID_CHUNGCHEONGNAMDO.name;
            data.cityTarget = MapboxCityPosition.ID_CHUNGCHEONGNAMDO.point;
            data.value = value;
            break;
          case "daegu":
            data.name = MapboxCityPosition.ID_DAEGU.name;
            data.cityTarget = MapboxCityPosition.ID_DAEGU.point;
            data.value = value;
            break;
          case "daejeon":
            data.name = MapboxCityPosition.ID_DAEJEON.name;
            data.cityTarget = MapboxCityPosition.ID_DAEJEON.point;
            data.value = value;
            break;
          case "gangwonDo":
            data.name = MapboxCityPosition.ID_GANGWONDO.name;
            data.cityTarget = MapboxCityPosition.ID_GANGWONDO.point;
            data.value = value;
            break;
          case "gwangju":
            data.name = MapboxCityPosition.ID_GWANGJU.name;
            data.cityTarget = MapboxCityPosition.ID_GWANGJU.point;
            data.value = value;
            break;
          case "gyeonggiDo":
            data.name = MapboxCityPosition.ID_GYEONGGIDO.name;
            data.cityTarget = MapboxCityPosition.ID_GYEONGGIDO.point;
            data.value = value;
            break;
          case "gyeongsangbukDo":
            data.name = MapboxCityPosition.ID_GYEONGSANGBUKDO.name;
            data.cityTarget = MapboxCityPosition.ID_GYEONGSANGBUKDO.point;
            data.value = value;
            break;
          case "gyeongsangnamDo":
            data.name = MapboxCityPosition.ID_GYEONGSANGNAMDO.name;
            data.cityTarget = MapboxCityPosition.ID_GYEONGSANGNAMDO.point;
            data.value = value;
            break;
          case "incheon":
            data.name = MapboxCityPosition.ID_INCHEON.name;
            data.cityTarget = MapboxCityPosition.ID_INCHEON.point;
            data.value = value;
            break;
          case "jejuDo":
            data.name = MapboxCityPosition.ID_JEJUDO.name;
            data.cityTarget = MapboxCityPosition.ID_JEJUDO.point;
            data.value = value;
            break;
          case "jeollabukDo":
            data.name = MapboxCityPosition.ID_JEOLLABUKDO.name;
            data.cityTarget = MapboxCityPosition.ID_JEOLLABUKDO.point;
            data.value = value;
            break;
          case "jeollanamDo":
            data.name = MapboxCityPosition.ID_JEOLLANAMDO.name;
            data.cityTarget = MapboxCityPosition.ID_JEOLLANAMDO.point;
            data.value = value;
            break;
          case "sejongSi":
            data.name = MapboxCityPosition.ID_SEJONG.name;
            data.cityTarget = MapboxCityPosition.ID_SEJONG.point;
            data.value = value;
            break;
          case "seoul":
            data.name = MapboxCityPosition.ID_SEOUL.name;
            data.cityTarget = MapboxCityPosition.ID_SEOUL.point;
            data.value = value;
            break;
          case "ulsan":
            data.name = MapboxCityPosition.ID_ULSAN.name;
            data.cityTarget = MapboxCityPosition.ID_ULSAN.point;
            data.value = value;
            break;
        }
        list.push(data);
      }
      this.cityList = list;

      if (this.isMapboxReady) {
        this.$refs.mapBox.center = [127.899222, 35.714442];
        this.$refs.mapBox.zoom = 5.3;
        this.$refs.mapBox.onUpdateCityValue(this.cityList);
      }
    },

    //LIVE 제외 한 영상 플레이 화면에 있는 곳에서 사용
    // parameter : targetType
    // - MapDataType.ID_DRONE
    // parameter : locationGroupData
    // - contentsId 사용 하여 api 요청
    // - contents location api 이용 결과 데이터 중 locations(타입 : array)만 파라미터
    onUpdatePlay: function (targetType, locationGroupData) {
      var createMapboxLayerData = this.createMapboxLayerData(
        targetType,
        null,
        locationGroupData,
        false
      );
      if (createMapboxLayerData == null || createMapboxLayerData == undefined) {
        this.$refs.mapBox.onRemovePlay()
        return;
      }
      createMapboxLayerData.offset = 0;
      this.playerData = createMapboxLayerData;
      this.movePoint = this.playerData.location; //수정
      if (this.isMapboxReady) {
        this.$refs.mapBox.onMovePosition(this.movePoint);
        this.$refs.mapBox.onUpdatePlay(this.playerData);
      }
    },

    //LIVE 제외 한 영상 플레이 화면에 있는 곳에서 사용
    //onUpdatePlay 함수로 데이터 생성 후 이용.
    //동영상의 재생중인 현재 시간을 넣어준다.
    //**동영상의 타임 업데이트 함수 timeupdate 이용 하여 호출 해준다.
    onUpdatePlayPosition: function (time) {
      var sec = time;

      if (this.playerData != null && this.playerData.line.length > 0) {
        var line = this.playerData.line;
        var first = line[0];

        var isFindIndex = (element) => {
          var firstTime = first.time.getTime() / 1000;
          var filterTime = element.time.getTime() / 1000;
          var findTime = filterTime - firstTime;
          return sec <= findTime;
        };

        var findIndex = line.findIndex(isFindIndex);
        if (findIndex != -1) {
          if (findIndex > line.length) {
            findIndex = line.length;
          }
        } else {
          findIndex = 0;
        }
        this.playerData.offset = findIndex;
        this.$refs.mapBox.onUpdatePlay(this.playerData);
      }
    },

    //주소 이용 하여 맵박스 위치 이동 함수
    //주소 파라미터로 해서 호출
    //google api 이용 하여 이동
    //현장의 기본주소, 도로명 주소도 가능
    onAddrToMapboxMovePosition: function (addr) {
      const url =
        "https://dapi.kakao.com/v2/local/search/address.json?query=" +
          addr;
      axios
        .get(url, {
          headers : {
            "Authorization":"KakaoAK 8b2ba5acde398e492c2a0e5f05d8b2a8",
            "content-type":"application/json"
          }
        })
        .then((resp) => {
          var results = resp.data.documents;
          if (results.length > 0) {
            var longitude = results[0].x;
            var latitude = results[0].y;
            var point = [longitude, latitude];
            this.movePoint = point;
            if (this.isMapboxReady) {
              this.$refs.mapBox.onMovePosition(this.movePoint);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onLngLatToMapboxMovePosition: function (lng, lat) {
      var point = [lng, lat];
      this.movePoint = point;
      if (this.isMapboxReady) {
        this.$refs.mapBox.onMovePosition(this.movePoint);
      }
    },

    // 해당 아아디로 이름 변경시 사용
    // 리스트 이름과 맵박스 이름을 맟추기위해 사용
    // parameter : id
    //  - 해당 id 값
    // parameter : name
    //  - 변경할 이름
    onUpdateName: function (id, name) {
      this.$refs.mapBox.onUpdateMapDataName(id, name);
    },

    //* locationGroupData : contents location api 이용 결과 데이터 중 locations(타입 : array)만 파라미터
    //* locationGroupData : worker location api 이용 결과 데이터 중 locations(타입 : array)만 파라미터
    // 위치 구분 -----------------------------------------------
    // 사용위치 : Live
    // * 기본 2초마다 호출
    // parameter : targetType
    //  - MapDataType.ID_LIVE
    // parameter : targetOwner
    //  - MapboxTargetOwner.ID_OTHER : 화면에 live 데이터 리스트
    //  - MapboxTargetOwner.ID_MY    : 화면에 live 데이터 단일
    // 화면에 live 데이터 리스트
    //  - 리스트 갯수 만큼 contents location api 요청 후 함수 호출
    //  - linkedContentsId 값을 사용 : api 의 contentsId
    // 화면에 live 데이터 단일
    //  - Live play 상태 따라 2초 마다 호출 시작 및 중지
    //  - linkedContentsId 값을 사용 : api 의 contentsId
    // ** offset 값이 필요 시 onMapboxLayerDataFindOffset 이용 하여 가져오기
    // 위치 구분 -----------------------------------------------
    // 사용위치 : 현장별 상황판 > map
    // parameter : targetType
    //  - MapDataType.ID_DRONE      : drone 또는 디바이스
    //  - MapDataType.ID_USER       : member 또는 현장지원
    //  - MapDataType.ID_CONTROLLER : controller 또는 조종자
    // parameter : targetOwner
    // - MapboxTargetOwner.ID_OTHER
    // parameter : name
    //  - drone, member, controller 각각의 화면중 NO 값
    // * member, controller -> list 데이터 사이즈 만큼 worker location api 요청, member, controller 의 workerId(worker_id), 현재 화면의 현장 eventId
    // * drone -> list 데이터 사이즈 만큼 contents location api 요청, drone 의 contentsId
    // ** 디바이스, 조종자, 현장지원, 게스트 의 전체 데이터 2초마다 호출
    async onUpdateMapboxLayerData (
      targetType,
      targetOwner,
      locationGroupData,
      name,
      pointType,
    ) {
      if (locationGroupData == undefined || locationGroupData.length == 0) {
        return;
      }

      if (
        (targetType == undefined || targetType == null) &&
        (targetOwner == undefined || targetOwner == null)
      ) {
        return;
      }
      if (targetType != undefined && targetType != null) {
        MapDataType.constructor(targetType);
      }

      if (targetOwner != undefined && targetOwner != null) {
        MapboxTargetOwner.constructor(targetOwner);
      }
      var mapboxLayerData = null;

      if(targetType == MapDataType.ID_POINT) {
        mapboxLayerData = this.createMapboxLayerPointData(
            targetType,
            targetOwner,
            locationGroupData,
            true,
            name,
            pointType
        );
      } else {
       mapboxLayerData = this.createMapboxLayerData(
            targetType,
            targetOwner,
            locationGroupData,
            true,
            name,
            pointType
        );
      }


      if (mapboxLayerData == undefined || mapboxLayerData == null) {
        return;
      }
      var updateLayerData = null;
      var find = this.$refs.mapBox.onFindSymbolLayerIndex(mapboxLayerData.id);
      if (find == null) {
        switch (targetType) {
          case MapDataType.ID_LIVE:
            switch (targetOwner) {
              case MapboxTargetOwner.ID_MY: {
                this.liveMyData = mapboxLayerData;
                break;
              }
              case MapboxTargetOwner.ID_OTHER: {
                this.liveOtherDataList.push(mapboxLayerData);
                break;
              }
            }
            break;
          case MapDataType.ID_USER: {
            mapboxLayerData.rotate = 0;
            switch (targetOwner) {
              case MapboxTargetOwner.ID_MY: {
                this.userMyData = mapboxLayerData;
                break;
              }
              case MapboxTargetOwner.ID_OTHER: {
                this.userOtherDataList.push(mapboxLayerData);
                break;
              }
            }
            break;
          }
          case MapDataType.ID_CONTROLLER: {
            mapboxLayerData.rotate = 0;
            switch (targetOwner) {
              case MapboxTargetOwner.ID_OTHER: {
                this.controllerOtherDataList.push(mapboxLayerData);
                break;
              }
            }
            break;
          }
          case MapDataType.ID_DRONE: {
            switch (targetOwner) {
              case MapboxTargetOwner.ID_MY: {
                this.droneMyData = mapboxLayerData;
                break;
              }
              case MapboxTargetOwner.ID_OTHER: {
                this.droneOtherDataList.push(mapboxLayerData);
                break;
              }
            }
            break;
          }
          case MapDataType.ID_POINT : {
            this.pointDataList.push(mapboxLayerData)
          }
        }
        updateLayerData = mapboxLayerData;
      } else {
        var isFindIndex = (element) => {
          return element.id == mapboxLayerData.id;
        };
        var findIndex = -1;
        switch (targetType) {
          case MapDataType.ID_LIVE: {
            switch (targetOwner) {
              case MapboxTargetOwner.ID_MY: {
                updateLayerData = this.liveMyData;
                break;
              }
              case MapboxTargetOwner.ID_OTHER: {
                findIndex = this.liveOtherDataList.findIndex(isFindIndex);
                if (findIndex != -1) {
                  updateLayerData = this.liveOtherDataList[findIndex];
                } else {
                  this.liveOtherDataList.push(mapboxLayerData);
                }
                break;
              }
            }
            break;
          }
          case MapDataType.ID_USER: {
            mapboxLayerData.rotate = 0;
            switch (targetOwner) {
              case MapboxTargetOwner.ID_MY: {
                updateLayerData = this.userMyData;
                break;
              }
              case MapboxTargetOwner.ID_OTHER: {
                findIndex = this.userOtherDataList.findIndex(isFindIndex);
                if (findIndex != -1) {
                  updateLayerData = this.userOtherDataList[findIndex];
                } else {
                  this.userOtherDataList.push(mapboxLayerData);
                }
                break;
              }
            }
            break;
          }
          case MapDataType.ID_CONTROLLER: {
            mapboxLayerData.rotate = 0;
            switch (targetOwner) {
              case MapboxTargetOwner.ID_OTHER: {
                findIndex = this.controllerOtherDataList.findIndex(isFindIndex);
                if (findIndex != -1) {
                  updateLayerData = this.controllerOtherDataList[findIndex];
                } else {
                  this.controllerOtherDataList.push(mapboxLayerData);
                }
                break;
              }
            }
            break;
          }
          case MapDataType.ID_DRONE: {
            switch (targetOwner) {
              case MapboxTargetOwner.ID_MY: {
                this.droneMyData = mapboxLayerData;
                break;
              }
              case MapboxTargetOwner.ID_OTHER: {
                findIndex = this.droneOtherDataList.findIndex(isFindIndex);
                if (findIndex != -1) {
                  updateLayerData = this.droneOtherDataList[findIndex];
                } else {
                  this.droneOtherDataList.push(mapboxLayerData);
                }
                break;
              }
            }
            break;
          }
          case MapDataType.ID_POINT: {
            findIndex = this.droneOtherDataList.findIndex(isFindIndex);
            if (findIndex != -1) {
              updateLayerData = this.droneOtherDataList[findIndex];
            } else {
              this.droneOtherDataList.push(mapboxLayerData);
            }
            break;
          }
        }
        if (updateLayerData != null) {
          if( mapboxLayerData.line.length > 0) {
            const listSize = mapboxLayerData.offset - updateLayerData.offset;
            if (listSize == mapboxLayerData.line.length) {
              mapboxLayerData.line.forEach((element) => {
                updateLayerData.line.push(element);
              });
            } else {
              updateLayerData.line = mapboxLayerData.line;
            }
          }
          updateLayerData.offset = mapboxLayerData.offset;
          updateLayerData.location = mapboxLayerData.location;
        }
      }
      if (this.isMapboxReady && updateLayerData != null) {
        this.$refs.mapBox.onUpdateMapData(updateLayerData);
      }
    },

    // 특정 영역 숨기기 ( 현장별 상황판 > map 에 주로 사용)
    // parameter : targetType
    //  - MapDataType.ID_DRONE      : drone 또는 디바이스
    //  - MapDataType.ID_USER       : member 또는 현장지원
    //  - MapDataType.ID_CONTROLLER : controller 또는 조종자
    // parameter : targetOwner
    //  - MapboxTargetOwner.ID_OTHER
    // parameter : isShow
    //  - true  : 보여짐
    //  - false : 숨김
    onIsShowTypeMapboxLayerData: function (targetType, targetOwner, isShow) {
      if (
        (targetType == undefined || targetType == null) &&
        (targetOwner == undefined || targetOwner == null)
      ) {
        return;
      }
      if (targetType != undefined && targetType != null) {
        MapDataType.constructor(targetType);
      }

      if (targetOwner != undefined && targetOwner != null) {
        MapboxTargetOwner.constructor(targetOwner);
      }

      switch (targetType) {
        case MapDataType.ID_USER: {
          this.userOtherDataList.forEach((item) => {
            if (this.isShowLine) {
              this.$refs.mapBox.onShowIdUpdateLine(item.id, isShow);
            }
            this.$refs.mapBox.onShowIdUpdateSymbol(item.id, isShow);
          });
          break;
        }
        case MapDataType.ID_DRONE: {
          this.droneOtherDataList.forEach((item) => {
            if (this.isShowLine) {
              this.$refs.mapBox.onShowIdUpdateLine(item.id, isShow);
            }
            this.$refs.mapBox.onShowIdUpdateSymbol(item.id, isShow);
          });
          break;
        }
        case MapDataType.ID_CONTROLLER: {
          this.controllerOtherDataList.forEach((item) => {
            if (this.isShowLine) {
              this.$refs.mapBox.onShowIdUpdateLine(item.id, isShow);
            }
            this.$refs.mapBox.onShowIdUpdateSymbol(item.id, isShow);
          });
          break;
        }
        case MapDataType.ID_POINT: {
          this.pointDataList.forEach((item) => {
            this.$refs.mapBox.onShowIdUpdateSymbol(item.id, isShow);
          });
          break;
        }
      }
    },

    // 현재 선택 된 아이디 칼라 또는 이미지 변경
    // 위치 구분 -----------------------------------------------
    // 사용위치 : 현장별 상황판 > map
    // parameter : targetType
    //  - MapDataType.ID_DRONE      : drone 또는 디바이스
    //  - MapDataType.ID_USER       : member 또는 현장지원
    //  - MapDataType.ID_CONTROLLER : controller 또는 조종자
    // parameter : targetOwner
    //  - MapboxTargetOwner.ID_MY    : 선택, "#FF5B46", 선택 이미지 변경
    //  - MapboxTargetOwner.ID_OTHER : 선택 풀림, "#707070", 다른 이미지 변경
    // parameter : id
    //  - contentsId          : drone 또는 디바이스
    //  - workerId(worker_id) : member 또는 현장지원
    //  - workerId(worker_id) : controller 또는 조종자
    // 위치 구분 -----------------------------------------------
    // 사용위치 : 화면에 live 데이터 리스트
    // parameter : targetType
    //  - MapDataType.ID_LIVE
    // parameter : targetOwner
    //  - MapboxTargetOwner.ID_MY : 선택, "#FF5B46",
    //  - 단일 플레이 화면 이기 때문에 다른 선택된 값 자동으로 풀림
    // parameter : id
    //  - 데이터 중 linkedContentsId 값
    onUpdateMapboxChangeColor: function (targetType, targetOwner, id) {
      if (
        (targetType == undefined || targetType == null) &&
        (targetOwner == undefined || targetOwner == null)
      ) {
        return;
      }
      if (targetType != undefined && targetType != null) {
        MapDataType.constructor(targetType);
      }

      if (targetOwner != undefined && targetOwner != null) {
        MapboxTargetOwner.constructor(targetOwner);
      }

      this.$refs.mapBox.onUpdateMapboxChangeColor(targetType, targetOwner, id);
    },

    // 맵박스 모든 데이터 초기화 : onClearMapboxLayerData()
    // 주로 종합상황판 전체 LIVE 목록에 사용, 페이지 이동 시 호출
    // targetType 구분 초기화 : onClearMapboxLayerData(targetType)
    // targetType, targetOwner 구분 초기화 : onClearMapboxLayerData(targetType,targetOwner)
    onClearMapboxLayerData: function (targetType, targetOwner) {
      if (
        (targetType == undefined || targetType == null) &&
        (targetOwner == undefined || targetOwner == null)
      ) {
        this.clearAllMapboxLayerData();
      }
      if (targetType != null && targetType != undefined) {
        MapDataType.constructor(targetType);
      }
      if (targetOwner != null && targetOwner != undefined) {
        MapboxTargetOwner.constructor(targetOwner);
      }

      switch (targetType) {
        case MapDataType.ID_CITY: {
          this.cityList = null;
          break;
        }
        case MapDataType.ID_LIVE: {
          if(this.liveMyData != null) {
            this.$refs.mapBox.onRemoveIdToData(this.liveMyData.id);
            this.liveMyData = null;
          }
          this.liveOtherDataList.forEach((dataItem) => {
            this.$refs.mapBox.onRemoveIdToData(dataItem.id);
          });
          this.liveOtherDataList.splice(0, this.liveOtherDataList.length);
          break;
        }
      }
    },

    // contents location api 중 offset 조건 값 가져오는 합수
    // 위치 구분 -----------------------------------------------
    // 사용위치 : 현장별 상황판 > map
    // parameter : targetType
    //  - MapDataType.ID_DRONE      : drone 또는 디바이스
    //  - MapDataType.ID_USER       : member 또는 현장지원
    //  - MapDataType.ID_CONTROLLER : controller 또는 조종자
    // parameter : targetOwner
    //  - MapboxTargetOwner.ID_OTHER
    // 위치 구분 -----------------------------------------------
    // 사용위치 : 화면에 live 데이터 리스트, 화면에 live 데이터 단일
    // parameter : targetType
    //  - MapDataType.ID_LIVE
    // parameter : targetOwner
    //  - MapboxTargetOwner.ID_MY : 화면에 live 데이터 단일
    //  - MapboxTargetOwner.ID_OTHER : 화면에 live 데이터 리스트
    onMapboxLayerDataFindOffset: function (targetType, targetOwner, id) {
      if (
        (targetType == undefined || targetType == null) &&
        (targetOwner == undefined || targetOwner == null)
      ) {
        return;
      }
      if (targetType != undefined && targetType != null) {
        MapDataType.constructor(targetType);
      }

      if (targetOwner != undefined && targetOwner != null) {
        MapboxTargetOwner.constructor(targetOwner);
      }

      var result = -1;

      var isFindIndex = (element) => {
        return element.id == id;
      };
      var findIndex = -1;
      switch (targetType) {
        case MapDataType.ID_LIVE: {
          switch (targetOwner) {
            case MapboxTargetOwner.ID_MY: {
              if(this.liveMyData != null) {
                result = this.liveMyData.offset;
              }
              break;
            }
            case MapboxTargetOwner.ID_OTHER: {
              findIndex = this.liveOtherDataList.findIndex(isFindIndex);
              if (findIndex != -1) {
                result = this.liveOtherDataList[findIndex].offset;
              }
              break;
            }
          }
          break;
        }
        case MapDataType.ID_USER: {
          switch (targetOwner) {
            case MapboxTargetOwner.ID_MY: {
              if(this.liveMyData != null) {
                result = this.userMyData.offset;
              }
              break;
            }
            case MapboxTargetOwner.ID_OTHER: {
              findIndex = this.userOtherDataList.findIndex(isFindIndex);
              if (findIndex != -1) {
                result = this.userOtherDataList[findIndex].offset;
              }
              break;
            }
          }
          break;
        }
        case MapDataType.ID_CONTROLLER: {
          switch (targetOwner) {
            case MapboxTargetOwner.ID_OTHER: {
              findIndex = this.controllerOtherDataList.findIndex(isFindIndex);
              if (findIndex != -1) {
                result = this.controllerOtherDataList[findIndex].offset;
              }
              break;
            }
          }
          break;
        }
        case MapDataType.ID_DRONE: {
          switch (targetOwner) {
            case MapboxTargetOwner.ID_MY: {
              if(this.liveMyData != null) {
                result = this.droneMyData.offset;
              }
              break;
            }
            case MapboxTargetOwner.ID_OTHER: {
              findIndex = this.droneOtherDataList.findIndex(isFindIndex);
              if (findIndex != -1) {
                result = this.droneOtherDataList[findIndex].offset;
              }
              break;
            }
          }
          break;
        }
      }
      return result;
    },

    // 맵박스 화면 데이터 삭제
    // 사용위치 : 현장별 상황판 > map
    // parameter : targetType
    //  - MapDataType.ID_DRONE      : drone 또는 디바이스
    //  - MapDataType.ID_USER       : member 또는 현장지원
    //  - MapDataType.ID_CONTROLLER : controller 또는 조종자
    // parameter : targetOwner
    //  - MapboxTargetOwner.ID_OTHER
    // parameter : list
    // - (drone 또는 디바이스), (member 또는 현장지원), (controller 또는 조종자) 각각의 리스트 데이터
    // - 또는 각각의 아이디롤 해당 object : { id : '각각의 아이디' } 로 된 array 데이터
    onMapboxLayerDataRelease: function (targetType, targetOwner, list) {
      var mapboxDataList = null;
      switch (targetType) {
        case MapDataType.ID_USER: {
          switch (targetOwner) {
            case MapboxTargetOwner.ID_OTHER: {
              mapboxDataList = this.userOtherDataList; //수정
              break;
            }
          }
          break;
        }
        case MapDataType.ID_CONTROLLER: {
          switch (targetOwner) {
            case MapboxTargetOwner.ID_OTHER: {
              mapboxDataList = this.controllerOtherDataList;
              break;
            }
          }
          break;
        }
        case MapDataType.ID_DRONE: {
          switch (targetOwner) {
            case MapboxTargetOwner.ID_OTHER: {
              mapboxDataList = this.droneOtherDataList;
              break;
            }
          }
          break;
        }
        case MapDataType.ID_LIVE:
          switch (targetOwner) {
            case MapboxTargetOwner.ID_OTHER: {
              mapboxDataList = this.liveOtherDataList;
              break;
            }
          }
          break;
        case MapDataType.ID_POINT:
          mapboxDataList = this.pointDataList;
          break;
      }

      if (list.length == 0) {
        mapboxDataList.forEach((dataItem) => {
          this.$refs.mapBox.onRemoveIdToData(dataItem.id);
        });
        mapboxDataList.splice(0, mapboxDataList.length);
      } else {
        if(targetType == MapDataType.ID_DRONE) {
          targetType;
        }

        var ids = this.mapboxDataRemoveAndIdList(list, mapboxDataList);

        ids.forEach((id) => {
          var isFindIndex = (element) => {
            return element.id == id;
          };
          var findSymbolIndex = mapboxDataList.findIndex(isFindIndex);
          if (findSymbolIndex != -1) {
            mapboxDataList.splice(findSymbolIndex, 1);
          }
        });
      }
    },

    //맵 타입 변경 이미지 선택
    onMapboxChangeSelectImageName: function (selectName) {
      this.isTypeSelectName = selectName;
      this.$refs.mapBox.onMapboxClearAllData();
      this.$refs.mapBox.onMapboxChangeType(this.isTypeSelectName);
    },

    //맵 포지션, 라인 버튼 클릭
    //화면에 따라 기본 라인 보여줄수 있음
    //이동경로 선택 시 라인 보이게 만듬
    onClickMapboxShowLine: function (isShowLine) {
      if (this.isShowLine != isShowLine) {
        this.isShowLine = isShowLine;
        this.$refs.mapBox.onShowAllUpdateMapLine(this.isShowLine);
      }
    },

    //맵 타입 변경 레이아웃 닫기
    onMapboxChangeClose: function () {
      this.isMapTypeChange = false;
    },

    //맵 타입 변경 레이아웃 보이기 및 닫기
    onMapboxChangeShow: function () {
      this.isMapTypeChange = !this.isMapTypeChange;
    },
    //맵 타입 변경 레이아웃 보이기 및 닫기
    onMapboxPointCreateShow: function () {
      if(this.isPointModify == true) {
        this.onMapboxPointModifyShow(null);
      }
      this.isPointCreate = !this.isPointCreate;
    },
    setMapboxPointChangeSelected : function(data, isSelected) {
      var isFindIndex = (element) => {
        return element.id == data.index;
      };
      var findIndex = this.pointDataList.findIndex(isFindIndex);
      if(findIndex != -1){
        if(isSelected == true) {
          this.$refs.mapBox.onPointSelect(this.pointDataList[findIndex])
        } else {
          this.$refs.mapBox.onPointUnselect(this.pointDataList[findIndex])
        }
      }
    },
    //맵 타입 변경 레이아웃 보이기 및 닫기
    onMapboxPointModifyShow: function (point) {
      if(this.isPointCreate == true) {
        this.onMapboxPointCreateShow();
      }

      if(this.pointData != null) {
        this.setMapboxPointChangeSelected(this.pointData, false)
      }

      if(point == null) {
        this.isPointModify = !this.isPointModify;
        if(this.isPointModify == false){
          this.$emit("point-modify-cancel");
          this.pointData = null;
          this.pointMemo = null;
        }
      } else {
        this.isPointModify = true;
        this.setMapboxPointChangeSelected(point, true)
      }

      this.pointData = point;
      if(point != null) {
        this.pointMemo = this.pointData.memo;
      }
    },

    mapboxDataRemoveAndIdList: function (list, oldList) {
      var idList = list.map((item) => {
        //수정
        if (item.deviceLogId != undefined) {
          return item.deviceLogId;
        }

        if (item.contentsId != undefined) {
          return item.contentsId;
        }

        if (item.workerId != undefined) {
          return item.workerId;
        }

        if (item.worker_id != undefined) {
          return item.worker_id;
        }

        if (item.id != undefined) {
          return item.id;
        }

        if (item.refId != undefined) {
          return item.refId;
        }
        if (item.index != undefined) {
          return item.index;
        }


      });

      var removeIdList = oldList
        .filter((dataItem) => {
          var isItem = false;
          idList.forEach((idItem) => {
            if (idItem == dataItem.id) {
              isItem = true;
              return;
            }
          });
          return !isItem;
        })
        .map((item) => item.id);
      if (removeIdList.length > 0) {
        removeIdList.forEach((id) => {
          this.$refs.mapBox.onRemoveIdToData(id);
        });
      }
      return removeIdList;
    },
    mapboxStyleChangeStartEvent: function (isChange) {
      if (isChange) {
        this.isMapboxReady = false;
        this.mapboxReadyTimer();
      }
    },
    clearAllMapboxLayerData: function () {
      this.cityList = null;

      this.playerData = null;

      this.userMyData = null;
      this.userOtherDataList = [];

      this.droneMyData = null;
      this.droneOtherDataList = [];

      this.controllerOtherDataList = [];

      this.liveMyData = null;
      this.liveOtherDataList = [];
      this.$refs.mapBox.onMapboxClearAllData();
    },
    mapboxReadyEvent: function (isReady) {
      this.isMapboxReady = isReady;
    },
    mapboxInitData: function () {
      if (this.cityList != null) {
        this.$refs.mapBox.center = [127.899222, 35.714442];
        this.$refs.mapBox.zoom = 5.3;
        this.$refs.mapBox.onUpdateCityValue(this.cityList);
      }

      if (this.playerData != null) {
        this.$refs.mapBox.center = [126.986, 37.565];
        this.$refs.mapBox.zoom = 10;
        this.$refs.mapBox.onUpdatePlay(this.playerData);
      }

      if (this.movePoint != null) {
        this.$refs.mapBox.onMovePosition(this.movePoint);
        this.movePoint = null;
      }

      if (this.liveMyData != null) {
        this.$refs.mapBox.onUpdateMapData(this.liveMyData);
      }

      if (this.liveOtherDataList.length > 0) {
        this.liveOtherDataList.forEach((item) => {
          this.$refs.mapBox.onUpdateMapData(item);
        });
      }

      if (this.userMyData != null) {
        this.$refs.mapBox.onUpdateMapData(this.userMyData);
      }

      if (this.userOtherDataList.length > 0) {
        this.userOtherDataList.forEach((item) => {
          this.$refs.mapBox.onUpdateMapData(item);
        });
      }

      if (this.droneMyData != null) {
        this.$refs.mapBox.onUpdateMapData(this.droneMyData);
      }

      if (this.droneOtherDataList.length > 0) {
        this.droneOtherDataList.forEach((item) => {
          this.$refs.mapBox.onUpdateMapData(item);
        });
      }

      if (this.controllerOtherDataList.length > 0) {
        this.controllerOtherDataList.forEach((item) => {
          this.$refs.mapBox.onUpdateMapData(item);
        });
      }

      if (this.pointDataList.length > 0) {
        this.pointDataList.forEach((item) => {
          this.$refs.mapBox.onUpdateMapData(item);
        });
      }
    },
    mapboxReadyTimer: function () {
      this.mapboxTimer = setInterval(() => {
        if (this.isMapboxReady) {
          this.mapboxInitData();
          clearInterval(this.mapboxTimer);
        }
      }, 1000);
    },
    locationGroupDataToMapboxPositionTimeData: function (locationGroupData) {
      var playLine = [];
      locationGroupData.forEach((item) => {
        if (item.lat != null && item.lng != null) {
          var value = Object.assign({}, MapboxPositionTimeData);
          value.index = item.index;
          value.location = [item.lng, item.lat];
          value.time = new Date(item.time);
          if (item.azimuth != undefined) {
            value.rotate = item.azimuth;
          }
          playLine.push(value);
        }
      });
      return playLine;
    },
    createMapboxLayerData: function (
      targetType,
      targetOwner,
      locationGroupData,
      isLastLocation,
      name,
      pointType
    ) {
      MapDataType.constructor(targetType);
      MapboxTargetOwner.constructor(targetOwner);

      if (locationGroupData == null || locationGroupData.length == 0) {
        return null;
      }

      locationGroupData.sort(function (a, b) {
        return a.index - b.index;
      });

      var isLocation = isLastLocation;
      if (isLocation == undefined) {
        isLocation = false;
      }

      var findLocationIndex = 0;
      if (isLocation) {
        findLocationIndex = locationGroupData.length - 1;
      }
      var location = locationGroupData[findLocationIndex];
      var playLine = this.locationGroupDataToMapboxPositionTimeData(locationGroupData);

      var data = Object.assign({}, MapboxLayerData);
      data.id = location.refId;
      data.location = [location.lng, location.lat];
      data.offset = location.index;
      data.line = playLine;
      data.rotate = location.azimuth;
      data.targetType = targetType;
      data.targetOwner = targetOwner;
      data.pointType = pointType;
      data.name = name;
      return data;
    },
    createMapboxLayerPointData: function (
        targetType,
        targetOwner,
        location,
        isLastLocation,
        name,
        pointType
    ) {
      MapDataType.constructor(targetType);
      MapboxTargetOwner.constructor(targetOwner);

      if(location == null) {
        return;
      }

      if(location.lng == undefined || location.lat == undefined || location.refId == undefined) {
        return;
      }

      var data = Object.assign({}, MapboxLayerData);
      data.id = location.refId;
      data.location = [location.lng, location.lat];
      data.offset = 0;
      data.line = [];
      data.rotate = 0;
      data.targetType = targetType;
      data.targetOwner = targetOwner;
      data.pointType = pointType;
      data.name = name;
      return data;
    },
    clearTimer() {
      clearInterval(this.mapboxTimer);
    },
  },
};
</script>

<style scoped>
img.-\33 612 {
  width: 40px;
  height: 40px;
  margin: 28px 0 41px 211.8px;
  object-fit: contain;
}

.map-select {
  width: 84px;
  height: 34px;
  padding: 8px 20px;
  border: 1px solid #ffffff;
  background-color: #d3d3d3;
}

.map-select:hover {
  border-color: #6278ff;
  /*border: 1px solid #d3d3d3;*/
}

.map-select.map-active {
  border: 1px solid #d3d3d3;
  background-color: #ffffff;
}

.map-select.map-active:hover {
  border-color: #6278ff;
  /*border: 1px solid #ffffff;*/
}

.map-type-change {
  position: absolute;
  width: 328px;
  height: 140px;
  top: 50%;
  left: 50%;
  margin: -70px 0 0 -164px;
  padding: 11px 12px 19px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: #000;
  z-index: 10;
  pointer-events: auto;
}

.map-type-change > span {
  font-family: NotoSansCJKkr;
  font-size: 1.8rem;;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.map-type-change > button {
  position: absolute;
  padding: 0px;
  width: 24px;
  height: 24px;
  right: 12px;
  top: 12px;
  border: 0px;
}

.map-type-change > button > image {
  display: contents;
}

.map-type-change > img {
  position: absolute;
  width: 80px;
  height: 45px;
  top: 51px;
  object-fit: contain;
  border-radius: 4px;
  border: solid 1px #aaaaaa;
}

.map-type-change > img.normal {
  left: 21px;
  background-image: url(../../../assets/images/map/map-type-change-normal.png);
}
.map-type-change > img.satellite {
  left: 124px;
  background-image: url(../../../assets/images/map/map-type-change-satellite.png);
}
.map-type-change > img.terrain {
  left: 228px;
  background-image: url(../../../assets/images/map/map-type-change-terrain.png);
}
.map-type-change > img.map-type-imag-active {
  border: solid 1px #00c4ff;
}


.map-point-div {
  position: absolute;
  top: calc(50% - 25px); /* 25px = 103px :map-point-create height/2 - 78 : image */
  left: calc(50% - 226px);
  pointer-events: auto;
}
.map-point-modify-div {
  position: absolute;
  top: calc(50% + 35px); /* 25px = 103px :map-point-create height/2 - 78 : image */
  left: calc(50% - 226px);
}
.map-point-div > div.select-image {
  text-align: center;
  padding-bottom: 10px;
}
.map-point-div > div > img.create {
  text-align: center;
  height: 51px;
}

  .map-point-create {
    width: 552px;
    height: 206px;
    background: white;
    padding: 10px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
  }


  .map-point-create > div.map-point-title{
    margin-bottom: 8px;
  }
  .map-point-create > div.map-point-title > span{
    font-weight: bold;
    font-size: 1.4rem;
  }


  .select-group > span{
    font-weight: bold;
    font-size: 1.2rem;
    margin: 5px 0px;
  }

/*.map-point-create > div.select-group {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
}*/

.map-point-create > div.select-group > div.select {
  display: inline-block;
  background-color: white;
  width: 15%;
  height: 30%;
  text-align: center;
  vertical-align: center;
  line-height: 40px;
  cursor: pointer;
}

.map-point-create > div.select-group > div.active {
  color: red;
}

.map-point-create > div > span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-size: 1.2rem;
}

.map-point-create > div.select-image {
  text-align: center;
  padding-top: 20px;
}

.map-point-create > div > img.create {
  text-align: center;
  height: 51px;
}

.map-point-create > div.insert-data > span {
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 1.2rem;
}

.map-point-create > div.insert-data > textarea {
  width: calc(80% - 10px);
  height: 53px;
  margin-right: 10px;
  margin-top: 8px;
  font-size: 1.2rem;
  background-color: white;
  resize: none;
}

.map-point-create > div.insert-data > .btn-wrap{
  text-align: center;
  margin: 10px 0px;
}

.map-point-create > div.map-point-data > span{
  font-weight: bold;
  font-size: 1rem;
  margin: 5px 0px;
}

.map-point-create > div.map-point-data > div.map-point-content{
  display: inline-block;
  vertical-align: center;
  margin-left: 20px;
}


</style>
