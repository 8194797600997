<template>
  <div class="pop">
    <div class="popWrap">
      <div class="box one popTitle flex">
        <h1 class="bold"><span></span>현장위치선택</h1>
      </div>
      <div class="flexB mapWrap">
        <div class="box map">
          <MapBox ref="mapbox" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

import MapBox from "@/components/map/mapbox/MapboxView";
import MapDataType from "@/components/map/enum/MapDataType";
import MapboxTargetOwner from "@/components/map/mapbox/MapboxTargetOwner";

import { fetchEvent } from "@/api/event";

export default {
  components: { MapBox },
  data() {
    return {
      moment: moment,
      viewSrc: "",
      contentsId: "",
      channelId: "",
      eventId: "",
      liveName: "",
      contentObj: {},
      eventObj: {},
    };
  },
  mounted() {
    this.viewSrc = this.$route.query.src;
    this.eventId = this.$route.query.eventId;
    this.mapPosition();
    this.interval = setInterval(() => {
      this.updateMapbox();
    }, 2000);
    if (this.channelId) {
      this.getLiveDetailByChnnelId();
    }
  },
  methods: {
    onTimeUpdate(time) {
      this.$refs.mapbox.onUpdatePlayPosition(time);
    },
    async mapPosition() {
      if(this.eventId == undefined) {
        return;
      }
      await fetchEvent(this.eventId).then((res) => {
        this.eventObj = res.data.data;
        if (res.data.result == 0) {
          this.$refs.mapbox.onAddrToMapboxMovePosition(res.data.data.address);
        }
      });
    },
    async updateMapbox() {
      this.$refs.mapbox.onUpdateMapboxChangeColor(
        MapDataType.ID_LIVE,
        MapboxTargetOwner.ID_MY,
        this.contentsId
        // "3b7b7b21-51c7-4509-a84b-8aa959ba0066"
      );
    },

    // getLiveDetailByContentsId() {
    //   fetchChannel(this.channelId).then((res) => {
    //     this.contentObj = res.data.data;
    //     this.contentsId = res.data.data.linkedContentsId;
    //     this.viewSrc = res.data.data.liveURL;
    //     fetchVodLocation(this.contentsId).then((res) => {
    //       if (res.data.result == 0) {
    //         this.$refs.mapbox.onUpdatePlay(
    //           MapDataType.ID_DRONE,
    //           res.data.data.locations
    //         );
    //       }
    //     });
    //   });
    // },
  },
};
</script>
