<template>
  <MglMap
    :style="{ height: mapHeight }"
    :accessToken="accessToken"
    :mapStyle.sync="mapStyle"
    :center="center"
    :zoom="zoom"
    @load="onMapLoaded"
    @styledataloading="onMapboxStyleDataLoading"
    ref="mglMap"
    :attribution-control = false
    :logo-position = "'top-left'"
  >
    <MglGeojsonLayer
      v-show="cityCircleLayerList.length > 0"
      v-for="city in cityCircleLayerList"
      :key="city.id"
      :sourceId="city.source.data.id"
      :source="city.source"
      :layerId="city.source.data.currentLayer.id"
      :layer="city.source.data.currentLayer"
    />

    <MglGeojsonLayer
      v-show="cityValueLayerList.length > 0"
      v-for="city in cityValueLayerList"
      :key="city.id"
      :sourceId="city.source.data.id"
      :source="city.source"
      :layerId="city.source.data.currentLayer.id"
      :layer="city.source.data.currentLayer"
    />

    <MglGeojsonLayer
      v-show="playFullList.length > 0"
      v-for="playFull in playFullList"
      :key="playFull.source.data.currentLayer.id"
      :sourceId="playFull.source.data.id"
      :source="playFull.source"
      :layerId="playFull.source.data.currentLayer.id"
      :layer="playFull.source.data.currentLayer"
    />
    <MglGeojsonLayer
      v-show="playPositionList.length > 0"
      v-for="playPosition in playPositionList"
      :key="playPosition.source.data.currentLayer.id"
      :sourceId="playPosition.source.data.id"
      :source="playPosition.source"
      :layerId="playPosition.source.data.currentLayer.id"
      :layer="playPosition.source.data.currentLayer"
    />
    <MglGeojsonLayer
      v-show="playSymbolList.length > 0"
      v-for="playSymbol in playSymbolList"
      :key="playSymbol.source.data.currentLayer.id"
      :sourceId="playSymbol.source.data.id"
      :source="playSymbol.source"
      :layerId="playSymbol.source.data.currentLayer.id"
      :layer="playSymbol.source.data.currentLayer"
    />
    <MglGeojsonLayer
      v-show="mapLineList.length > 0"
      v-for="line in mapLineList"
      :key="line.id + 'line'"
      :sourceId="line.source.data.id"
      :source="line.source"
      :layerId="line.source.data.currentLayer.id"
      :layer="line.source.data.currentLayer"
    />
    <MglGeojsonLayer
      v-show="mapSymbolList.length > 0"
      v-for="symbol in mapSymbolList"
      :key="symbol.id + 'symbol'"
      :sourceId="symbol.source.data.id"
      :source="symbol.source"
      :layerId="symbol.source.data.currentLayer.id"
      :layer="symbol.source.data.currentLayer"
      ref="symbole"
    />
  </MglMap>
</template>

<script>
//https://soal.github.io/vue-mapbox/guide/basemap.html#adding-map-component
import Mapbox from "mapbox-gl";
import { MglGeojsonLayer, MglMap } from "vue-mapbox";
import MapboxLayerName from "@/components/map/mapbox/MapboxLayerName";
import MapImageName from "@/components/map/enum/MapImageName";
import MapboxCityPositionData from "@/components/map/mapbox/MapboxCityPositionData";
import MapboxCityPosition from "@/components/map/mapbox/MapboxCityPosition";
import MapDataType from "@/components/map/enum/MapDataType";
import MapboxTargetOwner from "@/components/map/mapbox/MapboxTargetOwner";
import MapboxPositionTimeData from "@/components/map/mapbox/MapboxPositionTimeData";
import MapType from "@/components/map/enum/MapType";

import ic_mapbox_user from "@/assets/images/map/ic_map_user.svg";
import ic_mapbox_user_other from "@/assets/images/map/ic_map_user_other.svg";
import ic_mapbox_controller from "@/assets/images/map/ic_map_controller.svg";
import ic_mapbox_controller_other from "@/assets/images/map/ic_map_controller_other.svg";
import ic_mapbox_drone from "@/assets/images/map/ic_map_drone.svg";
import ic_mapbox_drone_other from "@/assets/images/map/ic_map_drone_other.svg";
import ic_mapbox_point_caution from "@/assets/images/map/ic_map_point_caution.svg";
import ic_mapbox_point_danger from "@/assets/images/map/ic_map_point_danger.svg";
import ic_mapbox_point_home from "@/assets/images/map/ic_map_point_home.svg";
import ic_mapbox_point_search from "@/assets/images/map/ic_map_point_search.svg";
import ic_mapbox_point_target from "@/assets/images/map/ic_map_point_target.svg";
import ic_mapbox_point_caution_selected from "@/assets/images/map/ic_map_point_caution.svg";
import ic_mapbox_point_danger_selected from "@/assets/images/map/ic_map_point_danger_selected.svg";
import ic_mapbox_point_home_selected from "@/assets/images/map/ic_map_point_home_selected.svg";
import ic_mapbox_point_search_selected from "@/assets/images/map/ic_map_point_search_selected.svg";
import ic_mapbox_point_target_selected from "@/assets/images/map/ic_map_point_target_selected.svg";

export default {
  components: {
    MglMap,
    MglGeojsonLayer,
  },
  props: {
    mapHeight: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      map: null,
      mapbox: null,
      isLineShow: false,
      accessToken:
        "pk.eyJ1IjoidGVpeG9uIiwiYSI6ImNrbTFxaDJ6bjRmdmgycHBtNm9nN3AzNGkifQ.KAR9UQbr6Lw6lL7dAy-www",
      mapStyle: "mapbox://styles/mapbox/streets-v11",
      center: [127.899222, 35.714442],
      zoom: 5.3,
      cityCircleLayerList: [],
      cityValueLayerList: [],

      playFullList: [],
      playPositionList: [],
      playSymbolList: [],

      mapSymbolList: [],
      mapLineList: [],

      ICON_PROPERTY: "icon_property",
      COLOR_PROPERTY: "color_property",

      cityList: null,
      myColorCode: "#FF5B46",
      otherColorCode: "#707070",

      isReady: false,
      baseData: {
        id: null,
        offset: 0,
        source: null,
        layer: null,
        isShow: true,
      },
    };
  },
  updated() {},
  mounted() {
    this.$nextTick(function () {
      // this.map.invalidateSize()
    });
  },
  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
    // this.mapbox.setRTLTextPlugin(
    //   "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
    //   null,
    //   true // Lazy load the plugin
    // );
  },
  methods: {
    onGetMapboxCenterData: function(){
      let position = {
        lng : this.$refs.mglMap.map.transform._center.lng,
        lat : this.$refs.mglMap.map.transform._center.lat,
      }
      return position;
    },
    onMapboxClearAllData: function () {
      if (this.playFull != null) {
        this.$refs.mglMap.map.removeLayer(this.playFull.layer.id);
        this.playFull = null;
      }

      if (this.playPosition != null) {
        this.$refs.mglMap.map.removeLayer(this.playFull.layer.id);
        this.playPosition = null;
      }
      if (this.playSymbol != null) {
        this.$refs.mglMap.map.removeLayer(this.playFull.layer.id);
        this.playSymbol = null;
      }

      if (this.mapSymbolList.length > 0) {
        this.mapSymbolList.forEach((element) => {
          this.$refs.mglMap.map.removeLayer(element.layer.id);
        });
        this.mapSymbolList = [];
      }

      if (this.mapLineList.length > 0) {
        this.mapLineList.forEach((element) => {
          this.$refs.mglMap.map.removeLayer(element.layer.id);
        });
        this.mapLineList = [];
      }


      if (this.cityList != null && this.cityList.length > 0) {
        this.cityList.forEach((element) => {
          this.$refs.mglMap.map.removeLayer(element.layer.id);
        });
        this.cityList = [];
      }
    },
    onMapboxChangeType: function (type) {
      var sendUrl = null;
      switch (type) {
        case MapType.ID_NORMAL.name: {
          sendUrl = MapType.ID_NORMAL.url;
          break;
        }
        case MapType.ID_SATELLITE.name: {
          sendUrl = MapType.ID_SATELLITE.url;
          break;
        }
        case MapType.ID_TERRAIN.name: {
          sendUrl = MapType.ID_TERRAIN.url;
          break;
        }
        default: {
          sendUrl = MapType.ID_NORMAL.url;
        }
      }
      if (sendUrl != null && sendUrl != this.mapStyle) {
        this.isReady = false;
        this.$emit("style-change-start-event", true);
        this.mapStyle = sendUrl;
      }
    },

    //circle 그리기
    getCircleSourceBase: function (id, sourceId, location, colorName) {
      var features = [];
      var feature = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: location,
        },
        properties: {
          color_property: colorName,
          id: id,
        },
      };

      features.push(feature);

      return {
        type: "geojson",
        cluster: false,
        data: {
          id: sourceId,
          currentLayer: null,
          sourceType: "point",
          type: "FeatureCollection",
          features: features,
        },
      };
    },
    getCircleLayerBase: function (targetType, symbolId) {
      var circleLayerBase = {
        id: symbolId,
        type: "circle",
        layout: {},
        paint: {
          "circle-color": [
            "case",
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_SEOUL.name,
            ],
            MapboxCityPosition.ID_SEOUL.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_INCHEON.name,
            ],
            MapboxCityPosition.ID_INCHEON.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_GWANGJU.name,
            ],
            MapboxCityPosition.ID_GWANGJU.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_SEJONG.name,
            ],
            MapboxCityPosition.ID_SEJONG.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_DAEGU.name,
            ],
            MapboxCityPosition.ID_DAEGU.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_DAEJEON.name,
            ],
            MapboxCityPosition.ID_DAEJEON.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_BUSAN.name,
            ],
            MapboxCityPosition.ID_BUSAN.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_GYEONGGIDO.name,
            ],
            MapboxCityPosition.ID_GYEONGGIDO.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_GANGWONDO.name,
            ],
            MapboxCityPosition.ID_GANGWONDO.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_GYEONGSANGBUKDO.name,
            ],
            MapboxCityPosition.ID_GYEONGSANGBUKDO.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_GYEONGSANGNAMDO.name,
            ],
            MapboxCityPosition.ID_GYEONGSANGNAMDO.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_CHUNGCHEONGBUKDO.name,
            ],
            MapboxCityPosition.ID_CHUNGCHEONGBUKDO.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_CHUNGCHEONGNAMDO.name,
            ],
            MapboxCityPosition.ID_CHUNGCHEONGNAMDO.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_JEOLLABUKDO.name,
            ],
            MapboxCityPosition.ID_JEOLLABUKDO.color,
            [
              "==",
              ["get", this.COLOR_PROPERTY],
              MapboxCityPosition.ID_JEOLLANAMDO.name,
            ],
            MapboxCityPosition.ID_JEOLLANAMDO.color,
            ["==", ["get", this.COLOR_PROPERTY], MapboxTargetOwner.ID_OTHER],
            this.otherColorCode,
            ["==", ["get", this.COLOR_PROPERTY], MapboxTargetOwner.ID_MY],
            this.myColorCode,
            MapboxCityPosition.ID_JEJUDO.color,
          ],
          "circle-radius": 12,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#ffffff",
        },
      };

      return circleLayerBase;
    },

    //symbol 그리기
    getSymbolSourceBase: function (id, sourceId, location, iconName, title, rotate) {
      var initRotate = rotate;
      if (rotate == undefined) {
        initRotate = 0;
      }
      var initName = title;
      if (initName == undefined) {
        initName = "";
      }
      var features = [];
      var feature = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: location,
        },
        properties: {
          icon_property: iconName,
          id: id,
          title: initName,
          rotate: initRotate,
        },
      };

      features.push(feature);

      return {
        type: "geojson",
        cluster: false,
        data: {
          id: sourceId,
          currentLayer: null,
          sourceType: "point",
          type: "FeatureCollection",
          features: features,
        },
      };
    },
    getSymbolLayerBase: function (targetType, symbolId, isShow) {
      var iconSize = 0.36;
      var textColor = "#ffffff";

      var visibility = "visible";
      if (isShow != undefined && !isShow) {
        visibility = "none";
      }

      switch (targetType) {
        case MapDataType.ID_USER:
        case MapDataType.ID_LIVE:
        case MapDataType.ID_POINT: {
          textColor = "#000000";
          break;
        }
      }

      var symbolLayerBase = {
        id: symbolId,
        type: "symbol",
        layout: {
          visibility: visibility,
          "icon-image": [
            "case",
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_USER],MapImageName.ID_ICON_USER,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_DRONE],MapImageName.ID_ICON_DRONE,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_OTHER_DRONE,],MapImageName.ID_ICON_OTHER_DRONE,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_OTHER_USER,],MapImageName.ID_ICON_OTHER_USER,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_CONTROLLER,],MapImageName.ID_ICON_CONTROLLER,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_OTHER_CONTROLLER,],MapImageName.ID_ICON_OTHER_CONTROLLER,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_POINT_CAUTION,],MapImageName.ID_ICON_POINT_CAUTION,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_POINT_DANGER,],MapImageName.ID_ICON_POINT_DANGER,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_POINT_HOME,],MapImageName.ID_ICON_POINT_HOME,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_POINT_SEARCH,],MapImageName.ID_ICON_POINT_SEARCH,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_POINT_TARGET,],MapImageName.ID_ICON_POINT_TARGET,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_POINT_CAUTION_SELECTED,],MapImageName.ID_ICON_POINT_CAUTION_SELECTED,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_POINT_DANGER_SELECTED,],MapImageName.ID_ICON_POINT_DANGER_SELECTED,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_POINT_HOME_SELECTED,],MapImageName.ID_ICON_POINT_HOME_SELECTED,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_POINT_SEARCH_SELECTED,],MapImageName.ID_ICON_POINT_SEARCH_SELECTED,
            ["==", ["get", this.ICON_PROPERTY],MapImageName.ID_ICON_POINT_TARGET_SELECTED,],MapImageName.ID_ICON_POINT_TARGET_SELECTED,
            "",
          ], // reference the image
          // 'icon-image':MapImageName.ID_BLUE_CIRCLE, // reference the image
          "icon-size": iconSize,
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          "text-field": "{title}",
          "icon-rotate": ["get", "rotate"],
        },
        paint: {
          "text-color": textColor,
        },
      };

      return symbolLayerBase;
    },

    //line 그리기
    getLineSourceBase: function (id, sourceId, locationList, colorName) {
      var locations = [];
      locationList.forEach((element) => {
        var data = MapboxPositionTimeData.constructor(element);
        locations.push(data.location);
      });

      return {
        type: "geojson",
        data: {
          id: sourceId,
          currentLayer: null,
          sourceType: "line",
          type: "Feature",
          properties: {
            id: id,
            color_property: colorName,
          },
          geometry: {
            type: "LineString",
            coordinates: locations,
          },
        },
      };
    },
    getLineLayerBase: function (lineId, isShow) {
      var visibility = "none";
      if (isShow != undefined && isShow) {
        visibility = "visible";
      }

      return {
        id: lineId,
        type: "line",
        layout: {
          visibility: visibility,
        },
        paint: {
          "line-width": 4.5,
          "line-color": [
            "case",
            ["==", ["get", this.COLOR_PROPERTY], MapboxTargetOwner.ID_MY],
            this.myColorCode,
            this.otherColorCode,
          ],
        },
      };
    },

    onFindSymbolLayerIndex: function (id) {
      var result = null;
      if (this.mapSymbolList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == id;
        };
        var find = this.mapSymbolList.findIndex(isFindIndex);
        if (find != -1) {
          result = find;
        }
      }
      return result;
    },
    onFindLineLayerIndex: function (id) {
      var result = null;
      if (this.mapSymbolList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == id;
        };
        var find = this.mapSymbolList.findIndex(isFindIndex);
        if (find != -1) {
          result = find;
        }
      }
      return result;
    },

    onUpdateCityValue: function (list) {
      this.$refs.mglMap.map.scrollZoom.disable();
      this.$refs.mglMap.map.dragPan.disable()

      list.forEach((element) => {
        var data = MapboxCityPositionData.constructor(element);
        MapboxCityPosition.constructor(data.cityTarget);
       MapImageName.constructor(data.iconName);

        data.cityTarget.sort(function (a, b) {
          return b - a;
        });

        var sourceId = MapboxLayerName.ID_CIRCLE + data.name;
        var layerId = MapboxLayerName.ID_CIRCLE + data.name;

        var createCircleSource = this.getCircleSourceBase(
          data.iconName,
          sourceId,
          data.cityTarget,
          data.name
        );
        var createCircleLayer = this.getCircleLayerBase(
          MapDataType.ID_CITY,
          layerId
        );
        createCircleSource.data.currentLayer = createCircleLayer;

        var cityCircleData = Object.assign({}, this.baseData);
        cityCircleData.id = data.iconName;
        cityCircleData.source = createCircleSource;
        cityCircleData.layer = createCircleLayer;
        this.cityCircleLayerList.push(cityCircleData);

        sourceId = MapboxLayerName.ID_SYMBOL + data.name;
        layerId = MapboxLayerName.ID_SYMBOL + data.name;
        var createSymbolSource = this.getSymbolSourceBase(
          data.iconName,
          sourceId,
          data.cityTarget,
          null,
          data.value
        );
        var createSymbolLayer = this.getSymbolLayerBase(
          MapDataType.ID_CITY,
          layerId,
          data.iconName
        );
        createSymbolSource.data.currentLayer = createSymbolLayer;

        var cityData = Object.assign({}, this.baseData);
        cityData.id = data.iconName;
        cityData.source = createSymbolSource;
        cityData.layer = createSymbolLayer;
        this.cityValueLayerList.push(cityData);
      });
    },

    onRemovePlay: function(){
      if(this.playFullList.length > 0) {
        if (this.$refs.mglMap.map.getLayer(this.playFullList[0].layer.id)) {
          this.$refs.mglMap.map.removeLayer(this.playFullList[0].layer.id);
        }
      }

      if(this.playPositionList.length > 0) {
        if (this.$refs.mglMap.map.getLayer(this.playPositionList[0].layer.id)) {
          this.$refs.mglMap.map.removeLayer(this.playPositionList[0].layer.id);
        }
      }

      if(this.playSymbolList.length > 0) {
        if (this.$refs.mglMap.map.getLayer(this.playSymbolList[0].layer.id)) {
          this.$refs.mglMap.map.removeLayer(this.playSymbolList[0].layer.id);
        }
      }
    },
    onUpdatePlay: function (mapboxLayerData) {
      MapDataType.constructor(mapboxLayerData.targetType);
      var linePlayerFullId = MapboxLayerName.ID_LINE_PLAYER_FULL + "_" + mapboxLayerData.id;
      var linePlayerPositionId = MapboxLayerName.ID_LINE_PLAYER_POSITION + "_" + mapboxLayerData.id;
      var symbolPlayerId = MapboxLayerName.ID_SYMBOL + "_" + mapboxLayerData.id;
      var iconName = this.getIconName(mapboxLayerData.targetType, null);

      if (
        this.playFullList.length > 0 &&
        this.playFullList[0].layer.id != linePlayerFullId
      ) {
        if (this.$refs.mglMap.map.getLayer(this.playFullList[0].layer.id)) {
          this.$refs.mglMap.map.removeLayer(this.playFullList[0].layer.id);
        }

        if (this.$refs.mglMap.map.getLayer(this.playPositionList[0].layer.id)) {
          this.$refs.mglMap.map.removeLayer(this.playPositionList[0].layer.id);
        }

        if (this.$refs.mglMap.map.getLayer(this.playSymbolList[0].layer.id)) {
          this.$refs.mglMap.map.removeLayer(this.playSymbolList[0].layer.id);
        }

        this.playFullList.splice(0, 1);
        this.playPositionList.splice(0, 1);
        this.playSymbolList.splice(0, 1);
      }

      if (mapboxLayerData.line == null || mapboxLayerData.line.length <= 0) {
        return;
      }

      if (this.playFullList.length == 0) {
        //동영상 풀 경로 셋팅
        var playFull = Object.assign({}, this.baseData);
        playFull.id = mapboxLayerData.id;
        playFull.source = this.getLineSourceBase(
          mapboxLayerData.id,
          linePlayerFullId,
          mapboxLayerData.line,
          MapboxTargetOwner.ID_OTHER
        );
        playFull.layer = this.getLineLayerBase(linePlayerFullId, true);
        playFull.source.data.currentLayer = playFull.layer;
        this.playFullList.push(playFull);

        //동영상 현재 경로 셋팅
        var playPosition = Object.assign({}, this.baseData);
        playPosition.id = mapboxLayerData.id;
        playPosition.source = this.getLineSourceBase(
          mapboxLayerData.id,
          linePlayerPositionId,
          mapboxLayerData.line.slice(0, 1),
          MapboxTargetOwner.ID_MY
        );
        playPosition.layer = this.getLineLayerBase(linePlayerPositionId, true);
        playPosition.source.data.currentLayer = playPosition.layer;
        this.playPositionList.push(playPosition);

        //동영상 현재 위치 셋팅
        var point = mapboxLayerData.location;
        var playSymbol = Object.assign({}, this.baseData);
        playSymbol.id = mapboxLayerData.id;
        playSymbol.source = this.getSymbolSourceBase(
          mapboxLayerData.id,
          symbolPlayerId,
          point,
          iconName,
          null,
          mapboxLayerData.rotate
        );
        playSymbol.layer = this.getSymbolLayerBase(mapboxLayerData.targetType, symbolPlayerId);
        playSymbol.source.data.currentLayer = playSymbol.layer;
        this.playSymbolList.push(playSymbol);
        this.center = point;
      } else {
        var position = mapboxLayerData.line[mapboxLayerData.offset];

        if (
          this.playPositionList.length > 0 &&
          this.playPositionList[0].source.data.geometry.coordinates.length >
            0 &&
          mapboxLayerData.offset >= 0
        ) {
          this.playPositionList[0].source.data.geometry.coordinates =
            this.playFullList[0].source.data.geometry.coordinates.slice(
              0,
              mapboxLayerData.offset + 1
            );
        }

        if (position != null && this.playSymbolList.length > 0) {
          this.center = position.location;
          if (position.rotate != null && position.rotate != undefined) {
            this.playSymbolList[0].source.data.features[0].properties[
              "rotate"
            ] = position.rotate;
          }
          this.playSymbolList[0].source.data.features[0].geometry.coordinates =
            position.location;
        }
      }
    },

    onUpdateMapboxChangeColor: function (targetType, targetOwner, id) {
      MapDataType.constructor(targetType);
      MapboxTargetOwner.constructor(targetOwner);
      const iconName = this.getIconName(targetType, targetOwner);

      const findSymbolIndex = this.onFindSymbolLayerIndex(id);
      const findLineIndex = this.onFindLineLayerIndex(id);

      if (findSymbolIndex != null) {
        const findSymbol = this.mapSymbolList[findSymbolIndex];
        if (targetType == MapDataType.ID_LIVE) {
          this.mapSymbolList.forEach((item) => {
            if (item.id != findSymbol.id) {
              findSymbol.source.data.features[0].properties[
                this.ICON_PROPERTY
              ] =MapImageName.ID_ICON_OTHER_DRONE;
            }
          });
        }
        findSymbol.source.data.features[0].properties[this.ICON_PROPERTY] = iconName;
      }

      if (findLineIndex != null) {
        const findLine = this.mapLineList[findLineIndex];
        if (targetType == MapDataType.ID_LIVE) {
          this.mapLineList.forEach((item) => {
            if (item.id != findLine.id) {
              findLine.source.data.properties[this.COLOR_PROPERTY] =
                MapboxTargetOwner.ID_OTHER;
            }
          });
        }
        findLine.source.data.properties[this.COLOR_PROPERTY] = targetOwner;
      }
    },
    onUpdatePointData: function (mapboxLayerData) {
      MapDataType.constructor(mapboxLayerData.targetType);
      MapboxTargetOwner.constructor(mapboxLayerData.targetOwner);
      const symbolId = MapboxLayerName.ID_SYMBOL + "_" + mapboxLayerData.id;

      const iconName = this.getIconName(
          mapboxLayerData.targetType,
          mapboxLayerData.targetOwner,
          mapboxLayerData.pointType,
      );

      var findSymbolIndex = this.onFindSymbolLayerIndex(mapboxLayerData.id);
      if (findSymbolIndex == null) {

        const baseSymbolData = Object.assign({}, this.baseData);
        const point = mapboxLayerData.location;
        baseSymbolData.id = mapboxLayerData.id;
        baseSymbolData.source = this.getSymbolSourceBase(
            mapboxLayerData.id,
            symbolId,
            point,
            iconName,
            mapboxLayerData.name,
            mapboxLayerData.rotate
        );
        baseSymbolData.layer = this.getSymbolLayerBase(
            mapboxLayerData.targetType,
            symbolId
        );
        baseSymbolData.source.data.currentLayer = baseSymbolData.layer;

        this.pointList.push(baseSymbolData);

      } else {
        const findSymbol = this.pointList[findSymbolIndex];
        const position = mapboxLayerData.location;
        findSymbol.source.data.features[0].properties["rotate"] = mapboxLayerData.rotate;
        if (position != null && position != undefined) {
          findSymbol.source.data.features[0].geometry.coordinates = position;
        }
      }
    },
    onUpdateMapData: function (mapboxLayerData) {
      MapDataType.constructor(mapboxLayerData.targetType);
      MapboxTargetOwner.constructor(mapboxLayerData.targetOwner);
      const lineId = MapboxLayerName.ID_LINE + "_" + mapboxLayerData.id;
      const symbolId = MapboxLayerName.ID_SYMBOL + "_" + mapboxLayerData.id;

      const isLineShow = this.isLineShow;

      const iconName = this.getIconName(
        mapboxLayerData.targetType,
        mapboxLayerData.targetOwner,
        mapboxLayerData.pointType,
      );

      var findSymbolIndex = this.onFindSymbolLayerIndex(mapboxLayerData.id);
      if (findSymbolIndex == null) {
        const baseLineData = Object.assign({}, this.baseData);
        baseLineData.id = mapboxLayerData.id;
        baseLineData.source = this.getLineSourceBase(
          mapboxLayerData.id,
          lineId,
          mapboxLayerData.line,
          mapboxLayerData.targetOwner
        );
        baseLineData.layer = this.getLineLayerBase(lineId, isLineShow);
        baseLineData.offset = mapboxLayerData.line.length;
        baseLineData.source.data.currentLayer = baseLineData.layer;
        this.mapLineList.push(baseLineData);

        const baseSymbolData = Object.assign({}, this.baseData);
        const point = mapboxLayerData.location;
        baseSymbolData.id = mapboxLayerData.id;
        baseSymbolData.source = this.getSymbolSourceBase(
          mapboxLayerData.id,
          symbolId,
          point,
          iconName,
          mapboxLayerData.name,
          mapboxLayerData.rotate
        );
        baseSymbolData.layer = this.getSymbolLayerBase(
          mapboxLayerData.targetType,
          symbolId
        );
        baseSymbolData.source.data.currentLayer = baseSymbolData.layer;

        this.mapSymbolList.push(baseSymbolData);

      } else {
        var findLineIndex = this.onFindLineLayerIndex(mapboxLayerData.id);
        const findSymbol = this.mapSymbolList[findSymbolIndex];
        const findLine = this.mapLineList[findLineIndex];
        const position = mapboxLayerData.location;

        findSymbol.source.data.features[0].properties["rotate"] = mapboxLayerData.rotate;
        if (position != null && position != undefined) {
          findSymbol.source.data.features[0].geometry.coordinates = position;
        }
        findLine.offset = mapboxLayerData.offset;
        var newLine = [];
        mapboxLayerData.line.forEach((element) => {
          newLine.push(element.location);
        });
        findLine.source.data.geometry.coordinates = newLine;
      }
    },
    onUpdateMapDataName: function (id, name) {
      const findId = this.onFindSymbolLayerIndex(id);
      if (findId != null) {
        const findSymbol = this.mapSymbolList[findId];
        findSymbol.source.data.features[0].properties["title"] =
          name.toString();
      }
    },
    getIconName(type, owner, pointType) {
      var result =MapImageName.ID_ICON_USER;
      switch (type) {
        case MapDataType.ID_LIVE:
        case MapDataType.ID_DRONE:
          {
            result =MapImageName.ID_ICON_DRONE;
            if (owner == MapboxTargetOwner.ID_OTHER) {
              result =MapImageName.ID_ICON_OTHER_DRONE;
            }
          }
          break;
        case MapDataType.ID_USER:
          {
            result =MapImageName.ID_ICON_USER;
            if (owner == MapboxTargetOwner.ID_OTHER) {
              result =MapImageName.ID_ICON_OTHER_USER;
            }
          }
          break;
        case MapDataType.ID_CONTROLLER:
          {
            result =MapImageName.ID_ICON_CONTROLLER;
            if (owner == MapboxTargetOwner.ID_OTHER) {
              result =MapImageName.ID_ICON_OTHER_CONTROLLER;
            }
          }
          break;
        case MapDataType.ID_POINT:
        {
          if(pointType != null) {
            result =MapImageName.ID_ICON_POINT_HOME;
            switch (pointType.toString()) {
              case "Caution" : {
                result =MapImageName.ID_ICON_POINT_CAUTION;
                break;
              }
              case "Target" :{
                result =MapImageName.ID_ICON_POINT_TARGET;
                break;
              }
              case "Search" :{
                result =MapImageName.ID_ICON_POINT_SEARCH;
                break;
              }
              case "Danger" :{
                result =MapImageName.ID_ICON_POINT_DANGER;
                break;
              }
            }
          }
        }
        break;
      }
      return result;
    },

    onShowIdUpdateSymbol: function (id, isSymbolShow) {
      var visibility = "none";
      if (isSymbolShow) {
        visibility = "visible";
      }
      var findSymbolIndex = this.onFindSymbolLayerIndex(id);
      if (findSymbolIndex != null) {
        this.mapSymbolList[findSymbolIndex].layer.layout.visibility =
          visibility;
      }
    },
    onShowIdUpdateLine: function (id, isLineShow) {
      var visibility = "none";
      if (isLineShow) {
        visibility = "visible";
      }

      var findLineIndex = this.onFindLineLayerIndex(id);
      if (findLineIndex != null) {
        this.mapLineList[findLineIndex].layer.layout.visibility = visibility;
      }
    },
    onShowAllUpdateSymbol: function (isSymbolShow) {
      var visibility = "none";
      if (isSymbolShow) {
        visibility = "visible";
      }
      if (this.mapSymbolList.length > 0) {
        this.mapSymbolList.forEach((item) => {
          item.layer.layout.visibility = visibility;
        });
      }
    },
    onShowAllUpdateMapLine: function (isLineShow) {
      var visibility = "none";
      if (isLineShow) {
        visibility = "visible";
      }
      this.isLineShow = isLineShow;

      if (this.mapLineList.length > 0) {
        this.mapLineList.forEach((item) => {
          item.layer.layout.visibility = visibility;
        });
      }
    },

    onRemoveIdToData: function (id) {
      var findSymbolIndex = this.onFindSymbolLayerIndex(id);
      var findLineIndex = this.onFindLineLayerIndex(id);

      if (findSymbolIndex != null) {
        var symbolLayer = this.mapSymbolList[findSymbolIndex];
        this.$refs.mglMap.map.removeLayer(symbolLayer.layer.id);
        this.mapSymbolList.splice(findSymbolIndex, 1);
      }
      if (findLineIndex != null) {
        var lineLayer = this.mapLineList[findLineIndex];
        this.$refs.mglMap.map.removeLayer(lineLayer.layer.id);
        this.mapLineList.splice(findLineIndex, 1);
      }
    },

    onMapLoaded: function (event) {
      // in component
      // this.map = this.$refs.mglMap.map
      this.mapboxLoadImages(event.map);
      this.isReady = true;
      this.$emit("ready-event", this.isReady);
    },
    onMapboxStyleDataLoading: function (event) {
      this.mapboxLoadImages(event.map);
      this.isReady = true;
      this.$emit("ready-event", this.isReady);
    },

    onPointUnselect : function(mapboxLayerData){
      MapDataType.constructor(mapboxLayerData.targetType);
      MapboxTargetOwner.constructor(mapboxLayerData.targetOwner);

      const iconName = this.getIconName(
          mapboxLayerData.targetType,
          mapboxLayerData.targetOwner,
          mapboxLayerData.pointType,
      );
      const findSymbolIndex = this.onFindSymbolLayerIndex(mapboxLayerData.id);

      if (findSymbolIndex != null) {
        const findSymbol = this.mapSymbolList[findSymbolIndex];
        findSymbol.source.data.features[0].properties[this.ICON_PROPERTY] = iconName;
      }
    },

    onPointSelect : function(mapboxLayerData){
      MapDataType.constructor(mapboxLayerData.targetType);
      MapboxTargetOwner.constructor(mapboxLayerData.targetOwner);
      var select = "_SELECTED";
      const iconName = this.getIconName(
          mapboxLayerData.targetType,
          mapboxLayerData.targetOwner,
          mapboxLayerData.pointType,
      );
      const findSymbolIndex = this.onFindSymbolLayerIndex(mapboxLayerData.id);

      if (findSymbolIndex != null) {
        const findSymbol = this.mapSymbolList[findSymbolIndex];

        findSymbol.source.data.features[0].properties[this.ICON_PROPERTY] = iconName + select;
      }
    },

    //지도 이동
    onMovePosition: function (point) {
      if (point != null) {
        this.zoom = 15.0;
        this.center = point;
      }
    },

    //이미지 맵박스 적용
    mapboxLoadImages: function (map) {
      map.loadImage(ic_mapbox_user, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_USER, image);
      });

      map.loadImage(ic_mapbox_user_other, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_OTHER_USER, image);
      });

      map.loadImage(ic_mapbox_controller, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_CONTROLLER, image);
      });

      map.loadImage(ic_mapbox_controller_other, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_OTHER_CONTROLLER, image);
      });

      map.loadImage(ic_mapbox_drone, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_DRONE, image);
      });

      map.loadImage(ic_mapbox_drone_other, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_OTHER_DRONE, image);
      });

      map.loadImage(ic_mapbox_point_danger, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_POINT_DANGER, image);
      });
      map.loadImage(ic_mapbox_point_search, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_POINT_SEARCH, image);
      });
      map.loadImage(ic_mapbox_point_target, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_POINT_TARGET, image);
      });
      map.loadImage(ic_mapbox_point_caution, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_POINT_CAUTION, image);
      });
      map.loadImage(ic_mapbox_point_home, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_POINT_HOME, image);
      });

      map.loadImage(ic_mapbox_point_danger_selected, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_POINT_DANGER_SELECTED, image);
      });
      map.loadImage(ic_mapbox_point_search_selected, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_POINT_SEARCH_SELECTED, image);
      });
      map.loadImage(ic_mapbox_point_target_selected, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_POINT_TARGET_SELECTED, image);
      });
      map.loadImage(ic_mapbox_point_caution_selected, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_POINT_CAUTION_SELECTED, image);
      });
      map.loadImage(ic_mapbox_point_home_selected, (error, image) => {
        if (error) console.error(error);
        map.addImage(MapImageName.ID_ICON_POINT_HOME_SELECTED, image);
      });
    },
  },
};
</script>

<style scoped>
/*@import "node_modules/mapbox-gl/dist/mapbox-gl.css";*/

</style>
