const MapKoreaCityPosition = Object.freeze({
  //서울
  ID_SEOUL: {
    point: [37.540705, 126.956764],
    name: "ID_SEOUL",
    color: "#00B9AA",
  },

  //인천
  ID_INCHEON: {
    point: [37.469221, 126.573234],
    name: "ID_INCHEON",
    color: "#1F48FF",
  },

  //광주
  ID_GWANGJU: {
    point: [35.15972, 126.85306],
    name: "ID_GWANGJU",
    color: "#FF5300",
  },
  //세종특별시
  ID_SEJONG: {
    point: [36.4875, 127.28167],
    name: "ID_SEJONG",
    color: "#61BFFF",
  },
  //대구광역시
  ID_DAEGU: {
    point: [35.798838, 128.583052],
    name: "ID_DAEGU",
    color: "#9B00FF",
  },

  //울산
  ID_ULSAN: {
    point: [35.519301, 129.239078],
    name: "ID_ULSAN",
    color: "#20BE19",
  },
  //대전광역시
  ID_DAEJEON: {
    point: [36.321655, 127.378953],
    name: "ID_DAEJEON",
    color: "#FF9900",
  },

  //부산광역시
  ID_BUSAN: {
    point: [35.198362, 129.053922],
    name: "ID_BUSAN",
    color: "#EF2D00",
  },

  //경기도
  ID_GYEONGGIDO: {
    point: [37.567167, 127.190292],
    name: "ID_GYEONGGIDO",
    color: "#008EFF",
  },
  //강원도
  ID_GANGWONDO: {
    point: [37.555837, 128.209315],
    name: "ID_GANGWONDO",
    color: "#FF36B2",
  },
  //경상북도
  ID_GYEONGSANGBUKDO: {
    point: [36.248647, 128.664734],
    name: "ID_GYEONGSANGBUKDO",
    color: "#FFB800",
  },
  //경상남도
  ID_GYEONGSANGNAMDO: {
    point: [35.259787, 128.664734],
    name: "ID_GYEONGSANGNAMDO",
    color: "#00F467",
  },

  //충청북도
  ID_CHUNGCHEONGBUKDO: {
    point: [36.628503, 127.929344],
    name: "ID_CHUNGCHEONGBUKDO",
    color: "#DC0097",
  },
  //충청남도
  ID_CHUNGCHEONGNAMDO: {
    point: [36.557229, 126.779757],
    name: "ID_CHUNGCHEONGNAMDO",
    color: "#DC9016",
  },

  //전라북도
  ID_JEOLLABUKDO: {
    point: [35.716705, 127.144185],
    name: "ID_JEOLLABUKDO",
    color: "#D80000",
  },
  //전라남도
  ID_JEOLLANAMDO: {
    point: [34.8194, 126.893113],
    name: "ID_JEOLLANAMDO",
    color: "#00C4C0",
  },

  //제주특별자치도
  ID_JEJUDO: {
    point: [33.364805, 126.542671],
    name: "ID_JEJUDO",
    color: "#6FEB46",
  },
});

export default MapKoreaCityPosition;
