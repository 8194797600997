const MapboxLayerName = Object.freeze({
  ID_SYMBOL: "ID_SYMBOL",
  ID_LINE: "ID_LINE",
  ID_CIRCLE: "ID_CIRCLE",

  ID_LINE_PLAYER_FULL: "ID_LINE_PLAYER_FULL",
  ID_LINE_PLAYER_POSITION: "ID_LINE_PLAYER_POSITION",
});

export default MapboxLayerName;
